import React from 'react';
import Container from 'components/Container/Container';
import ContactUs from 'components/ContactUs/ContactUs';
import TweenWrapper from 'components/TweenWrapper/TweenWrapper';
import './Footer.scss';

const Footer = () => (
  <TweenWrapper duration={100} toggleClass="Footer--blueBG" triggerName="footer">
    <div className="Footer">
      <Container size="sm">
        <ContactUs />
        <p className="Footer-address">© AIVIA, Inc 2024</p>
      </Container>
    </div>
  </TweenWrapper>
);

export default Footer;
